var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.modalTitle,"ok-text":_vm.modalOkLabel,"width":500,"confirm-loading":_vm.loading,"cancel-button-props":{ props: { disabled: _vm.loading } }},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.formObj}},[_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isUpdate),expression:"!isUpdate"}],attrs:{"label":_vm.$t('model_part.field.part')}},[_c('PartSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'part_id' ,
					{
						rules: [
						{ required: true, message: _vm.$tt('validate.required','model_part.field.part') },
						],
					},
				]),expression:"[\n\t\t\t\t\t'part_id' ,\n\t\t\t\t\t{\n\t\t\t\t\t\trules: [\n\t\t\t\t\t\t{ required: true, message: $tt('validate.required','model_part.field.part') },\n\t\t\t\t\t\t],\n\t\t\t\t\t},\n\t\t\t\t]"}],attrs:{"excluded-part-id-list":_vm.currentPartIdList},on:{"change":_vm.handlePartChange}})],1),_vm._v(" "),_c('PartLiteCard',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedPart != undefined),expression:"selectedPart != undefined"}],staticClass:"modal-part-card",attrs:{"part":_vm.selectedPart,"link-target":"_blank","bordered":true,"size":"small"}}),_vm._v(" "),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.canManageInitial),expression:"canManageInitial"}],attrs:{"label":_vm.$t('model_part.field.type')}},[_c('span',{attrs:{"slot":"extra"},domProps:{"innerHTML":_vm._s(_vm.$t('model_part.field.type.help'))},slot:"extra"}),_vm._v(" "),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'type' ,
					{
						rules: [
						{ required: true ,message: _vm.$tt('validate.required','model_part.field.type') },
						] ,
						'initialValue': 'initial',
					},
				]),expression:"[\n\t\t\t\t\t'type' ,\n\t\t\t\t\t{\n\t\t\t\t\t\trules: [\n\t\t\t\t\t\t{ required: true ,message: $tt('validate.required','model_part.field.type') },\n\t\t\t\t\t\t] ,\n\t\t\t\t\t\t'initialValue': 'initial',\n\t\t\t\t\t},\n\t\t\t\t]"}]},[_c('a-radio',{attrs:{"value":"initial"}},[_vm._v(_vm._s(_vm.$tenum('drone_part_type','initial')))]),_vm._v(" "),_c('a-radio',{attrs:{"value":"optional"}},[_vm._v(_vm._s(_vm.$tenum('drone_part_type','optional')))])],1)],1),_vm._v(" "),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.canManageInitial),expression:"!canManageInitial"}],attrs:{"label":_vm.$t('model_part.field.type')}},[_c('span',{staticClass:"ant-form-text"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.displayPartType)+"\n\t\t\t")])]),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('model_part.field.total'),"extra":_vm.$t('model_part.field.total.help')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['total']),expression:"['total']"}],staticClass:"myinput-number",attrs:{"min":0,"step":1,"precision":0,"length":"30"}}),_vm._v(" "),_c('span',{staticClass:"ant-form-text"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('common.part.unit'))+"\n\t\t\t")])],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('model_part.field.remark')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark']),expression:"['remark']"}],attrs:{"auto-size":{ minRows: 3,maxRows: 8 },"placeholder":_vm.$t('model_part.field.remark.placeholder')}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }