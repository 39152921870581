<i18n src="@i18n/drone/drone_model.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
model_part.title : "เพิ่มอะไหล่"
model_part.ok : "เพิ่ม"
model_part.title.update : "แก้ไขข้อมูลอะไหล่"
model_part.ok.update : "@:common.save"

model_part.field.part : "อะไหล่"
model_part.field.type : "ประเภท"
model_part.field.type.help : "<b>อะไหล่ตั้งต้น</b> - เป็นชิ้นส่วนที่มาพร้อมกับตัวเครื่อง<br /> <b>อะไหล่เสริม</b> - เป็นอะไหล่ที่สามารถติดตั้งเพิ่มเติม เช่นอะไหล่สำรอง หรือ อุปกรณ์เสริม เป็นต้น"

model_part.field.total.help : "ใน Drone 1 ตัวมีชิ้นส่วนนี้ได้กี่ชิ้น (ไม่ระบุ หรือ ค่า 0 = ไม่จำกัด)"
model_part.field.remark.placeholder : "หมายเหตุอะไหล่ใน Drone ชิ้นนี้ เช่น ใช้สำรองทดแทนอีกรุ่น เป็นต้น"
</i18n>

<template>
	<a-modal
		:visible="visible"
		:title="modalTitle"
		:ok-text="modalOkLabel"
		:width="500"
		:confirm-loading="loading"
		:cancel-button-props="{ props: { disabled: loading } }"
		@ok="handleOk"
		@cancel="handleCancel">
		<a-form layout='vertical' :form="formObj">
			<a-form-item v-show="!isUpdate" :label="$t('model_part.field.part')" >
				<PartSelect
					v-decorator="[
						'part_id' ,
						{
							rules: [
							{ required: true, message: $tt('validate.required','model_part.field.part') },
							],
						},
					]"
					:excluded-part-id-list="currentPartIdList"
					@change="handlePartChange"/>
			</a-form-item>

			<PartLiteCard v-show="selectedPart != undefined" :part="selectedPart"
				class="modal-part-card"  link-target="_blank"
				:bordered="true" size="small"/>

			<a-form-item v-show="canManageInitial" :label="$t('model_part.field.type')">
				<span slot="extra" v-html="$t('model_part.field.type.help')"></span>
				<a-radio-group
					v-decorator="[
						'type' ,
						{
							rules: [
							{ required: true ,message: $tt('validate.required','model_part.field.type') },
							] ,
							'initialValue': 'initial',
						},
					]">
					<a-radio value="initial">{{$tenum('drone_part_type','initial')}}</a-radio>
					<a-radio value="optional">{{$tenum('drone_part_type','optional')}}</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item v-show="!canManageInitial" :label="$t('model_part.field.type')">
				<span class="ant-form-text">
					{{displayPartType}}
				</span>
			</a-form-item>
			<a-form-item :label="$t('model_part.field.total')"
				:extra="$t('model_part.field.total.help')">
				<a-input-number
						v-decorator="['total']"
						:min="0" :step="1"
						:precision="0"
						class="myinput-number"
						length="30">
				</a-input-number>
				<span class="ant-form-text">
					{{$t('common.part.unit')}}
				</span>
			</a-form-item>
			<a-form-item :label="$t('model_part.field.remark')">
				<a-textarea
					v-decorator="['remark']"
					:auto-size="{ minRows: 3,maxRows: 8 }"
					:placeholder="$t('model_part.field.remark.placeholder')">
				</a-textarea>
			</a-form-item>


		</a-form>
	</a-modal>
</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import PartSelect from '@components/part/PartSelect.vue'
import PartLiteCard from '@components/part/PartLiteCard.vue'
import { updateFieldsValue } from '@utils/formUtil'
import {InputNumber,Radio} from "ant-design-vue"
import { mapGetters } from 'vuex'

export default {
	components : {
		PartSelect , PartLiteCard,
		"a-radio" : Radio, "a-radio-group" : Radio.Group,
		"a-input-number" : InputNumber
	} ,
	mixins : [HasAntdFormMixin] ,
	props : {
		model : {
			type : null,
			default : () => []
		} ,
		currentPartIdList : {
			type : Array,
			default : () => []
		} ,
		canManageInitial : {
			type : Boolean,
			default : false,
		} ,
		loading : {
			type : Boolean,
			default : false
		} ,
			}	,
	data() {
		return {
			selectedPart : undefined,

			visible : false,
			mode : '',
			partType : undefined
		}
	} ,
	computed : {
		...mapGetters('drone',['getPartById']) ,
		isUpdate() {
			return this.mode == 'update'
		} ,
		displayPartType() {
			if (this.isUpdate) {
				return this.$tenum('drone_part_type',this.partType)
			} else {
				return this.$tenum('drone_part_type','optional')
			}
		} ,
		modalTitle() {
			if (this.isUpdate) {
				return this.$t('model_part.title.update')
			} else {
				return this.$t('model_part.title')
			}
		} ,
		modalOkLabel() {
			if (this.isUpdate) {
				return this.$t('model_part.ok.update')
			} else {
				return this.$t('model_part.ok')
			}
		}
	} ,

	methods : {
		openPartModal(modelPart) {
			if (modelPart.part_id) {
				this.mode = 'update'
				this.selectedPart = modelPart.part
				this.partType = modelPart.type
			} else {
				this.mode = 'new'
				this.selectedPart = undefined
				this.partType = undefined
			}
			this.visible = true
			this.$nextTick(()=>{
				updateFieldsValue(this.formObj,modelPart)
			})
		} ,
		closePartModal() {
			this.visible = false
			this.formObj.resetFields()
			this.selectedPart = undefined;
			this.$emit('close')
		} ,
		formErrors(error) {
			this.formSubmitErrors(error)
		} ,


		handleOk() {
			this.formObj.validateFields((err, values) => {
				if (!err) {
					const submit = {...values};
					if (!this.isUpdate && !this.canManageInitial) {
						submit.type = 'optional'
					}
					this.$emit('submit',{
						formData : submit ,
						mode : this.mode,
						partId : this.selectedPart.id
					})
				} else {
					this.displayValidateErrorMessage()
				}
			});
		} ,
		handleCancel() {
			if (this.loading)
				return;
			this.closePartModal()
		} ,
		handlePartChange(partId) {
			if (!partId) {
				this.selectedPart = undefined;
			} else {
				this.selectedPart = this.getPartById(partId)
			}

		}
	}
}
</script>
<style lang="less">
.modal-part-card {
	margin-top : -16px;
	margin-bottom : 16px;
	border-color : @primary-color;
	border-style : dashed;
}
</style>
