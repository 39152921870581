<template>
	<a-popconfirm
		:get-popup-container="getPopupContainer"
		:overlay-class-name="cOverlayClassName"
		v-bind="$attrs"
		v-on="$listeners">
		<slot v-for="(_, name) in $slots" :slot="name" :name="name" />
	</a-popconfirm>
</template>

<script>
import {Popconfirm} from "ant-design-vue"
import PopupMixin from "@mixins/PopupMixin.vue"
export default {
	components : {
		"a-popconfirm" : Popconfirm,
	} ,
	mixins : [PopupMixin] ,
	props : {
		overlayClassName : {
			type : String,
			default : undefined
		} ,
	} ,
	computed : {
		cOverlayClassName() {
			let rtn = 'mypopconfirm';
			if (this.$notEmpty(this.overlayClassName)) {
				rtn += ' '+this.overlayClassName;
			}
			return rtn;
		}
	} ,
}
</script>
