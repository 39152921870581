<i18n locale="th" lang="yaml" >
 select.part.placeholder: "เลือกอะไหล่"
 part.field.sku : SKU
 part.field.name : ชื่อ
</i18n>

<template>
	<a-select
		class="myselect-part"
		dropdown-class-name="myselect-part-dropdown"
		allow-clear show-search
		:placeholder="$t('select.part.placeholder')"
		:value="value"
		:filter-option="filterOption"
		:get-popup-container="getPopupContainer"
		@change="handleChange">
		<a-select-option v-for="part in partOptions" :key="part.id"
			:data-search="part.searchContent"
			:value="part.id">
			<span v-if="!hideSKU" class="part-sku">{{part.sku}} </span>
			<span class="part-name">{{part.name}}</span>
		</a-select-option>
	</a-select>
</template>

<script>
import {Select} from "ant-design-vue"
import { mapState } from 'vuex'
import PopupMixin from "@mixins/PopupMixin.vue"
export default {
	components : {
		"a-select" : Select,
		"a-select-option" : Select.Option,
	} ,
	mixins : [PopupMixin] ,
	model: {
    prop: 'value',
    event: 'change'
  },
	props : {
		value : {
			type : null,
			default : undefined
		} ,
		hideSKU : {
			type : Boolean,
			default : false,
		} ,
		activePartsOnly : {
			type : Boolean,
			default : false,
		} ,
		excludedPartIdList : {
			type : Array,
			default : () => []
		}
	} ,
	computed : {
		...mapState('drone',['parts']) ,
		partOptions() {
			return this.parts.filter((part) => {
				if (this.activePartsOnly && part.status !== 'active')
					return false;
				else
					return !this.excludedPartIdList.includes(part.id)
			});
		} ,
	} ,

	methods : {
		filterOption(inputValue,option) {
			return option.data.attrs['data-search'].indexOf(inputValue.toLowerCase()) >= 0
		} ,
		handleChange(value) {
			this.$emit('change',value);
		}
	}
}
</script>

<style lang="less" scoped>
.myselect-part {
	min-width : 200px;
}
.myselect-part-dropdown .part-sku ,
.myselect-part .part-sku {
	color : @info-color;
	font-weight: 600;
}

</style>
